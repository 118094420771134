import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import {
    ButtonStyled,
    Card,
    FilterBar,
    GutterWrapper,
    PageSectionImageText,
    PageTemplateWrapper,
    ResourcesContainer
} from "@resourcehub/resourcehub-components";
import configGenerator from "@resourcehub/resourcehub-configuration";
import { getImage } from "gatsby-plugin-image";

const HeroContainer = styled.div`
    label: HeroContainer;
    > section {
        > div {
            width: 100%;

            > div {
                @media (max-width: 1200px) {
                    > div:nth-child(2) {
                        margin-top: 50px;

                        div:nth-child(2) {
                            text-align: center;
                        }
                    }
                }
                > div {
                    @media (max-width: 1200px) {
                        width: 60%;
                    }
                }
            }
        }
        width: 100%;
    }
    > section:nth-last-of-type(2) {
        margin-bottom: 20px;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        margin-top: 0;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
    }
`;

const Resources = ({ data }) => {
    const { items, site, stream, hero_image } = data;

    // Filter items with those in the selected stream
    const filteredItems = items.edges
        .filter((edge) => edge.node.data.streams.includes(stream.recordId))
        .map((item) => item.node);

    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "consumer",
        locale: site.siteMetadata.lang,
        page: "dynamic-resources"
    });

    config.page.helmet.meta_title = stream.data.seo_title || "";
    config.page.helmet.meta_description = stream.data.seo_description || "";
    config.page.helmet.canonical_url = stream.data.canonical_url || "";
    config.page.content.hero.panels[0].title.content = stream.data.display_name || "";
    config.page.content.hero.panels[0].body.content = stream.data.sub_title || "";
    config.page.content.hero.panels[0].image.src = getImage(hero_image);
    config.page.content.hero.panels[0].children = <ButtonStyled {...config.page.content.hero.panels[0].custom.cta} />;

    const { copyData } = config.page.content;

    // Build Cards
    const cards = filteredItems && filteredItems.map((node) => <Card copyData={copyData} key={node.id} node={node} />);

    return (
        <PageTemplateWrapper config={config}>
            x
            <HeroContainer>
                <PageSectionImageText {...config.page.content.hero} />
            </HeroContainer>
            <GutterWrapper>
                <ResourcesContainer>
                    <FilterBar copyData={copyData} vertical={site.siteMetadata.legacy.vertical} />
                    <div>{cards}</div>
                </ResourcesContainer>
            </GutterWrapper>
        </PageTemplateWrapper>
    );
};

export default Resources;

export const pageQuery = graphql`
    query itemsQuery($rawId: String!) {
        items: allAirtable(
            sort: { fields: data___date_added, order: DESC }
            filter: { table: { eq: "Items" }, data: { market: { eq: "CN" } } }
        ) {
            edges {
                node {
                    ...ItemsFragment
                }
            }
        }
        site {
            ...SiteFragment
        }
        stream: airtable(id: { eq: $rawId }) {
            ...StreamFragment
        }
        hero_image: file(relativePath: { eq: "images/section-1-img-2.png" }) {
            childImageSharp {
                gatsbyImageData(width: 700, height: 400, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
    }
`;
